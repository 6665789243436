/* general */

body {
    background-color: rgb(40, 40, 40);
    color: #ddd;
    padding-top: 70px;
}

h1 {
    color: #e11;
}

h2 {
    color: #4c4;
}

h3 {
    color: #44c;
}

h4 {
    color: #44c;
}

a {
    color: rgb(100, 200, 190);
}

a:hover {
    color: rgb(20, 120, 110);
}

// a:visited {
//     color: rgb(0, 100, 90);
// }

button {
    color: #3f3f3f;
}

/* navbar */

/*#navbar {
    padding-right: 10px; // because of navbar-fixed-top class
}

.navbar-collapse {
    width: 1000px;
    margin: auto;
}*/

/* content */

/*#content-wrap {
    width: 1000px;
    margin: auto;
    //border-left: solid gray 2px;
    //padding: 5px;
}*/

.container {
    max-width: 960px;
}

/*#content {
    width: 690px;
    margin-right: 5px;
    background-color: rgb(50, 50, 50);
    float: left;
}*/

#content {
    background-color: rgb(50, 50, 50);
}

/*#right-content {
    width: 300px;
    margin-left: 5px;
    background-color: rgb(50, 50, 50);
    float: right;
}*/

/*#right-content {
    background-color: rgb(50, 50, 50);
}*/

/* table */

th {
	background: #90c27f;
	height: 29px;
	padding-left: 12px;
	padding-right: 12px;
	color: #FFF;
	text-align: center;
	border-left: 1px solid #B6D59A;
	border-bottom: solid 2px #FFF;
}

td {
	padding-left: 12px;
    padding-right: 12px;
    color: #ddd;
}

#balls_patterns_table td {
    color: #555;
}

#balls_patterns_table a {
    color: rgb(50, 150, 140);
}

#clubs_patterns_table td {
    color: #555;
}

#clubs_patterns_table a {
    color: rgb(50, 150, 140);
}

#rings_patterns_table td {
    color: #555;
}

#rings_patterns_table a {
    color: rgb(50, 150, 140);
}

#passing_patterns_table td {
    color: #555;
}

#passing_patterns_table a {
    color: rgb(50, 150, 140);
}

/*table {
	
}

tr {
	
}

th, td {
	
}*/

/* forms */

input, textarea {
    color: #ddd;
    background-color: rgb(40, 40, 40);
    resize: none;
}

textarea {
    resize: none;
}

select, option {
    color: #ddd;
    background-color: rgb(40, 40, 40);
}

/* profile */

#profile-avatar {
    padding: 10px;
    float: right;
}

#profile-title {
    float: left;
}

#profile-pm {
    clear: both;
}

#profile-pm-link {
    padding: 10px;
    float: right;
}

#profile-requests {
    clear: both;
}

#profile-contacts {
    clear: both;
}

#profile-send-request {
    clear: both;
}

/*#profile-edit {
    clear: both;
}*/

/* news */

.news-form {
    margin-bottom: 5px;
}

.news {
    border: solid gray 2px;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.news-avatar {
    float: left;
    padding-right: 5px;
    padding-bottom: 5px;
}

.news-title {
    float: left;
    font-size: 150%;
}

.news-admin {
    float: right;
}

.news-content {
    clear: both;
}

.news-image {
    clear: both;
}

.news-date {
    float: left;
    margin: 0px;
}

.news-share {
    float: right;
}

.news-comment {
    clear: both;
    //margin-top: 5px;
}

.news-comment ul li {
    list-style-type: none;
}

/* blog */

.blog-title {
    float: left;
    font-size: 150%;
}

.blog-admin {
    float: right;
}

.blog-body {
    clear: both;
}

.blog-post {
    border: solid gray 2px;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* search input */

/* search input and countries, states, cities */

.typeahead-li {
    font-family: sans-serif;
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
}

.typeahead, .typeahead-lostfound, .tt-query, .tt-hint {
	border: 2px solid #CCCCCC;
	border-radius: 8px;
	font-size: 22px; /* Set input font size */
	height: 35px;
	line-height: 35px;
	outline: medium none;
	padding: 8px 12px;
	width: 350px;
}

.typeahead-lostfound {
    background-color: #FFFFFF;
    color: #555;
}

.typeahead {
    background-color: #FFFFFF;
    color: #555;
    // cursor: pointer;
}

.typeahead:focus, .typeahead-lostfound:focus {
	border: 2px solid #0097CF;
}

.tt-query {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
}

.tt-hint {
	color: #999999;
}

.tt-menu {
	background-color: #FFFFFF;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	margin-top: 12px;
	padding: 8px 0;
	width: 422px;
}

.tt-suggestion {
	font-size: 22px;  /* Set suggestion dropdown font size */
    padding: 3px 20px;
    color: #999;
}

.tt-suggestion:hover {
	cursor: pointer;
	background-color: #0097CF;
	color: #FFFFFF;
}

.tt-suggestion p {
	margin: 0;
}

/* footer */

#footer {
	width: 1000px;
	margin: 10px auto 0 auto;
    padding: 0 5px 20px 5px;
    color: rgb(40, 40, 40);
    background-color: #d3d3d3;
    clear: both;
	-moz-border-radius: 5px;
	-o-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow: 1px 1px 3px #89a;
    -o-box-shadow: 1px 1px 3px #89a;
    -webkit-box-shadow: 1px 1px 3px #89a;
    box-shadow: 1px 1px 3px #89a;
}

#footer a {
    color: darkblue;
}

#footer a:hover {
    color: rgb(0, 0, 50);
}

#footer p.bottom-left {
	float: left;
	margin: 0 0 20px 0;
	padding: 0;
	font-size: .95em;
}

#footer p.bottom-right {
	float: right;
	margin: 0 0 20px 0px;
	padding: 0;
	font-size: .95em;
}

/* dialogs */

.bootbox-body {
    color: grey;
}

/* sign in */

.container #login-row #login-column #login-box {
    max-width: 600px;
    height: 320px;
    border: 1px solid #9C9C9C;
}

.container #login-row #login-column #login-box #login-form {
    padding: 20px;
}

.container #login-row #login-column #login-box #login-form #register-link {
    margin-top: -85px;
}

/* registration */

.form-group {
   margin-bottom: 15px;
}

label {
   margin-bottom: 15px;
}

.register-control {
   font-size: 11px;
   padding-top: 3px;
}

.main-center {
    margin: 0 auto;
    max-width: 330px;
    padding: 40px 40px;
}

.login-button {
   margin-top: 5px;
}

.login-register {
   font-size: 11px;
   text-align: center;
}

/* cookies */

.cookie-bar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 7px 20px;
    color: #666;
    font-size: 0.9em;
    background-color: #f2f2f2;
    border-top: 1px solid #e4e4e4;
    text-align: center;
}

.cookie-bar a {
    text-decoration: underline;
}

.cookie-bar a:hover {
    text-decoration: none;
}

.cookie_btn {
    display: inline;
    margin-left: 15px;
    padding: 4px 10px;
    background-color: #55acee;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
}

.cookie_btn-error {
    background-color: #e84e40;
}

/* Bootstrap */

/* Flash messages */

.flash-info {
    background-color: rgb(100, 100, 255);
    color: rgb(255, 255, 255);
    font-weight: bold;
    padding: 5px;
    margin: 10px;
}

.flash-notice {
    background-color: rgb(150, 0, 0);
    color: rgb(255, 255, 255);
    font-weight: bold;
    padding: 5px;
    margin: 10px;
}

.flash-error {
    background-color: rgb(255, 0, 0);
    color: rgb(255, 255, 255);
    font-weight: bold;
    padding: 5px;
    margin: 10px;
}

.flash-ok {
    background-color: rgb(0, 200, 0);
    color: rgb(255, 255, 255);
    font-weight: bold;
    padding: 5px;
    margin: 10px;
}

.flash-success {
    background-color: rgb(0, 200, 0);
    color: rgb(255, 255, 255);
    font-weight: bold;
    padding: 5px;
    margin: 10px;
}

.flash-warning {
    background-color: rgb(150, 150, 0);
    color: rgb(255, 255, 255);
    font-weight: bold;
    padding: 5px;
    margin: 10px;
}
